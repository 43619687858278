import {
  ref, onMounted, getCurrentInstance
} from 'vue'
import {
  stickerUseCase,
  getListAgent,
  labelUseCase,
  labelAgentUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { IFormLabelAgent } from '@/utils/interfaces/iLabelAgent'
import { isEmpty } from 'lodash'

export default {
  name: 'LabelAgentForm',
  components: {
    Form,
    Field
  },
  props: {
    dataEdit: {
      default: () => null
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const store = useStore()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const dataEdit = ref(props?.dataEdit)
    const isTransfer = ref(dataEdit.value?.isTransfer)
    const titleForm = ref('Tambah Label Agen')
    const digitNomorGlobal = ref(store.getters['globalSetting/digitNumber'])
    const Id = ref(null)
    const route = useRoute()
    const listAgent = ref([])
    const listLabel = ref([])
    const isLoading = ref(true)
    const dataForm = ref<IFormLabelAgent>({
      labelId: null,
      agentId: null,
      qty: 0,
      destinationAgent: null,
      codePrefix: null,
      codeOffset: 0,
      min: null,
      max: null
    })
    const schema = yup.object({
      labelId: yup.string().required('Label harus diisi'),
      agentId: yup.string().required('Agen harus diisi'),
      qty: yup.number()
        .typeError('Kuantitas Label harus diisi')
        .min(1, 'Kuantitas Label minimal 1')
        .max(1000, 'Kuantitas Label maksimal 1000'),
      destinationAgent: yup.string()
        /* eslint-disable-next-line */
        .when('agentId', ([agentId], schema) => (isTransfer.value ? schema
          .required('Agen Tujuan tidak boleh kosong')
          .test('cant-same', 'Agen Tujuan tidak boleh sama dengan Agen Asal', (val) => {
            if (agentId === val) {
              return false
            } return true
          }) : schema.nullable())),
      codePrefix: yup.string().max(2, 'Prefix maksimal 2 karakter').nullable(),
      codeOffset: yup.number()
        .typeError('No Tiket harus diisi')
        .min(0, 'No Tiket minimal 0'),
      // .max(1000, 'No Tiket maksimal 1000'),
    })

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const dataAgent = async () => {
      const filters = {
        filter: 'IsActive eq true',
        select: '&$select=Id,Nama,Kode'
      }
      const response = await getListAgent(filters)
      listAgent.value = response.result
    }

    const dataLabel = async () => {
      const response = await labelUseCase.getAll({
        custom: [['IsActive', '=', true]],
        pageSize: 100
      })
      listLabel.value = response?.result?.Data ?? []
    }

    const proccessSubmit = async (values: IFormLabelAgent) => {
      store.dispatch('showLoading')
      const response = await labelAgentUseCase.submitData(dataEdit.value?.Guid, values)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitData = (values: IFormLabelAgent) => {
      proccessSubmit(values)
    }

    const changeQty = (value: any) => {
      console.log('onlyy', value)
    }

    const deleteData = () => {
      if (!dataEdit.value) {
        $toast.add({
          severity: 'error', detail: 'Gagal menghapus stiker, silahkan coba kembali', group: 'bc', life: 3000
        })
        return
      }
      $confirm.require({
        header: 'Stiker',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await stickerUseCase.deleteData(dataEdit.value.Id)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus stiker, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }

    const onChangeDrop = (v: any) => {
      console.log('on chnge drop', v)
    }

    onMounted(() => {
      console.log(store.state)
      if (isTransfer.value) {
        titleForm.value = 'Transfer Label Agen'
      }
      dataAgent()
      dataLabel()
      if (dataEdit.value) {
        dataForm.value = {
          labelId: dataEdit.value.LabelId,
          agentId: dataEdit.value.AgenId,
          qty: Number(dataEdit.value.Total) - Number(dataEdit.value.Used),
          codePrefix: null,
          codeOffset: 0,
          min: dataEdit.value.KodeLabelMin,
          max: dataEdit.value.KodeLabelMax
        }
        isLoading.value = false
      } else {
        isLoading.value = false
      }
    })
    const prepareLabel = (qty: number, codeOffset: string) => {
      const total = qty === 0 ? 0 : (qty - 1) + Number(codeOffset)
      const arrCode = []
      for (let i = 0; i < codeOffset.length; i++) {
        if (Number(codeOffset[i]) > 0) {
          break
        }
        arrCode.push(codeOffset[i])
      }
      const prepareCode = arrCode.length > 0 ? arrCode.toString().replace(/,/g, '') + total.toString() : total.toString()
      return prepareCode
    }
    return {
      titleForm,
      dataEdit,
      route,
      dataForm,
      schema,
      submitData,
      deleteData,
      closeSidebar,
      listAgent,
      listLabel,
      store,
      onChangeDrop,
      changeQty,
      isEmpty,
      isTransfer,
      isLoading,
      prepareLabel,
      digitNomorGlobal
    }
  }
}
